import React, {useEffect, useState} from 'react';
import "./BurgerMenu.css";
import {useDispatch} from "react-redux";

function BurgerMenu({}) {

    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        setInterval(() => {
            setScreenWidth(window.innerWidth);
        }, 100)
    }, []);

    async function handleSidebarToggle() {
        setIsActive((prevVisibility) => !prevVisibility);
        const isSidebarGrown = !isActive;
        dispatch({
            type: 'UPDATE_SITE', payload: [{isSidebarGrown}]
        });
    }

    return (
        <>
            <div id={"HeaderNavBurgermenu"}>
                {
                    screenWidth < 680 &&
                    <div className={`burger ${isActive ? 'active' : ''}`} onClick={handleSidebarToggle}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                }

            </div>
        </>
    );
}

export default BurgerMenu;