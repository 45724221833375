import React, {useState, useEffect} from 'react';
import "./Toast.css"

const Toast = ({message, delay, color}) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(false);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    if (!show) {
        return null;
    }

    return (
        <div className={`toast align-items-center m-2 rounded show`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-header">
                <img src="https://assets.uxnetwork.eu/logos/white.png" className="rounded me-2"
                     alt="logo"/>
                <strong className="me-auto">UX-Network</strong>
            </div>
            <div className="toast-body rounded-bottom" style={{backgroundColor: `${color}`}}>
                {message}
            </div>
        </div>
    );
};

export default Toast;