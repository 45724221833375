import React from 'react';
import { useTranslation } from 'react-i18next';
import "./Index.css";
import StreamerInfo from "./streamerInfo/StreamerInfo";
import { Link } from "react-router-dom";

function Index() {
    const { t } = useTranslation();

    return (
        <div id="Index">
            <div className="container">
                <header className="text-center custom-header header-welcome">
                    <video autoPlay muted loop className={"background-video"}>
                        <source src="https://cloud.uxnetwork.eu/index.php/s/kAMSZHMZqr22sQr/download/80mb.mp4" type="video/mp4" media="all and (min-width: 1200px)"/>
                        <source src="https://cloud.uxnetwork.eu/index.php/s/b9pqN5W6dFZDWgC/download/40mb.mp4" type="video/mp4" media="all and (min-width: 800px)"/>
                        <source src="https://cloud.uxnetwork.eu/index.php/s/F3GpzxcBzqYf5jH/download/4mb.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    <div className="custom-header-big text-center">
                        <h1>{t('welcome_title')}</h1>
                        <p>{t('welcome_description')}</p>
                        <img src="https://assets.uxnetwork.eu/logos/white.png" alt="logo"/>
                    </div>
                </header>
                <main>
                    <section className="text-center d-flex justify-content-center align-items-center flex-column">
                        <div className="custom-section d-flex">
                            <div className="info-box">
                                <div className="feature-box">
                                    <i className="bi bi-megaphone feature-icon icon"></i>
                                    <h3>{t('for_companies')}</h3>
                                    <p>{t('company_description')}</p>
                                    <Link to="/for-companies">
                                        <button>
                                            <h3>{t('learn_more')}</h3>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="info-box">
                                <div className="feature-box">
                                    <i className="bi bi-camera-reels feature-icon icon"></i>
                                    <h3>{t('for_creators')}</h3>
                                    <p>{t('creator_description')}</p>
                                    <Link to="/for-creators">
                                        <button>
                                            <h3>{t('learn_more')}</h3>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
            <StreamerInfo/>
        </div>
    );
}

export default Index;
