// actions.js

// Action-Typen
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Action Creators für User-Management
export const setUser = user => ({
    type: SET_USER,
    payload: user
});

export const clearUser = () => ({
    type: CLEAR_USER
});

// Action Creators für Alerts
export const addAlert = (message, type = 'info', delay = 3000) => ({
    type: ADD_ALERT,
    payload: { message, type, delay }
});

export const removeAlert = id => ({
    type: REMOVE_ALERT,
    payload: { id }
});
