import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearUser } from '../../../actions';
import { logoutUser } from '../../../api'; // Importiere die API-Funktion

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleLogout = async () => {
            try {
                const response = await logoutUser();
                dispatch(clearUser());
                dispatch({
                    type: 'ADD_ALERT', payload: {
                        message: response.message,
                        type: "success"
                    }
                });
                localStorage.removeItem("authToken")
                window.location = "/";
            } catch (error) {
                dispatch({
                    type: 'ADD_ALERT', payload: {
                        message: error.message,
                        type: "success"
                    }
                });
                console.error('Logout Fehler:', error);
            }
        };

        handleLogout();
    }, [dispatch]);

    return (
        <div>
            <h2>Logout...</h2>
        </div>
    );
};

export default Logout;
