import React from 'react';
import { useTranslation } from 'react-i18next';
import "./For-companies.css";

function ForCompanies() {
    const { t } = useTranslation();

    return (
        <div className="container">
            <header className="custom-header text-center">
                <h1>{t('companies_header')}</h1>
            </header>
            <main>
                <section className="custom-section d-flex justify-content-around">
                    <div className="info-box">
                        <i className="bi bi-briefcase-fill icon"></i>
                        <h2>{t('connect_with_talents')}</h2>
                        <p>{t('find_influencers')}</p>
                    </div>
                    <div className="info-box">
                        <i className="bi bi-bar-chart-line-fill icon"></i>
                        <h2>{t('how_it_works')}</h2>
                        <ol className="custom-list">
                            <li>{t('company_process1')}</li>
                            <li>{t('company_process2')}</li>
                            <li>{t('company_process3')}</li>
                        </ol>
                    </div>
                    <div className="info-box">
                        <i className="bi bi-stars icon"></i>
                        <h2>{t('benefits')}</h2>
                        <ul className="custom-list">
                            <li>{t('company_benefit1')}</li>
                            <li>{t('company_benefit2')}</li>
                            <li>{t('company_benefit3')}</li>
                        </ul>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default ForCompanies;
