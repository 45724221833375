import {useSelector} from 'react-redux';
import './Alert.css';
import Toast from "./toast/Toast";

const Alert = () => {
    const alerts = useSelector(state => state.alerts);

    return (
        <div id="Alert">
            <div className="position-fixed bottom-0 p-2 m-1 ToastWrapper">
                {alerts.map(alert => (
                    <Toast key={alert.id} elementId={alert.id} message={alert.message} delay={alert.delay} color={alert.color} />
                ))}
            </div>
        </div>
    );
};

export default Alert;
