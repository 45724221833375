// reducers/index.js

import { combineReducers } from 'redux';
import userReducer from './userReducer';
import alertReducer from './alertReducer';
import siteReducer from "./siteReducer";

const rootReducer = combineReducers({
    user: userReducer,
    alerts: alertReducer,
    site: siteReducer
});

export default rootReducer;
