import React, {useEffect} from "react";
import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {generateUUID} from "./utils/generateUUID";
import { useDispatch } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

//BASE SITES

import Footer from "./components/base/footer/Footer";
import Header from "./components/base/header/Header";

//SITES
import Index from "./components/sites/index/Index";
import Login from "./components/sites/login/Login";
import Register from "./components/sites/register/Register";
import Logout from "./components/sites/logout/Logout";
import Dashboard from "./components/sites/dashboard/Dashboard";
import SiteNotFound from "./components/sites/siteNotFound/SiteNotFound";
import Alert from "./components/alert/Alert";
import {checkAuthStatus} from "./api";
import {setUser} from "./actions";
import Verify from "./components/sites/verify/Verify";
import ForCreators from "./components/sites/for-creators/For-creators";
import ForCompanies from "./components/sites/for-companies/For-companies";
import Sidebar from "./components/base/sidebar/Sidebar";
function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("authToken")) {
            handleCheck()
        }
    }, [dispatch]);

    const handleCheck = async () => {
        try {
            const response = await checkAuthStatus();
            dispatch(setUser(response.payload));
            dispatch({
                type: 'ADD_ALERT', payload: {
                    message: response.message,
                    type: "success"
                }
            });
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <BrowserRouter>
            {/* HEADER */}
            <Sidebar key={generateUUID()}/>
            <Header key={generateUUID()}/>
            <div id="Content">
                <Routes>
                    <Route path="/" element={<Index key={generateUUID()}/>}/>
                    {/* STATIC SITES*/}
                    <Route path="/for-creators" element={<ForCreators key={generateUUID()}/>}/>
                    <Route path="/for-companies" element={<ForCompanies key={generateUUID()}/>}/>
                    {/* USER SITES */}
                    <Route path="/login" element={<Login key={generateUUID()}/>}/>
                    <Route path="/register" element={<Register key={generateUUID()}/>}/>
                    <Route path="/logout" element={<Logout key={generateUUID()}/>}/>
                    <Route path="/verify" element={<Verify key={generateUUID()}/>}/>
                    {/* PERSONAL DASHBOARD */}
                    <Route path="/dashboard/*" element={<Dashboard key={generateUUID()}/>}/>
                    {/* SITE NOT FOUND */}
                    <Route path="*" element={<SiteNotFound key={generateUUID()}/>}/>
                </Routes>
            </div>
            {/* FOOTER */}
            <Footer key={generateUUID()}/>
            <Alert key={generateUUID()}/>
        </BrowserRouter>
    );
}

export default App;
