import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../../api'; // Importiere die API-Funktion
import "./Login.css";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const handleLogin = async () => {
        try {
            const response = await loginUser({ username, password });
            dispatch({
                type: 'ADD_ALERT', payload: {
                    message: response.message,
                    type: "success"
                }
            });
            window.location = "/";
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'ADD_ALERT', payload: {
                    message: error.response.data.message,
                    type: "error"
                }
            });
            console.error('Login Fehler:', error);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("authToken")) {
            window.location = "/"
        }
    }, []);

    return (
        <div className={"loginCenter"}>
            <h2>Login</h2>
            <div>
                <p className={"smallText"}>Username:</p>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Max Mustermann"
                    name="username"
                />
            </div>
            <div>
                <p className={"smallText"}>Passwort:</p>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="IAmASecurePassword123!"
                    name="Password"
                />
            </div>
            <button onClick={handleLogin}>Login</button>
        </div>
    );
};

export default Login;
