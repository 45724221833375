const initialAlertState = {};

const siteReducer = (state = initialAlertState, action) => {
    switch (action.type) {
        case 'UPDATE_SITE':
            // Loggen des empfangenen Payloads zur Überprüfung
            let tempState = state;
            action.payload.map((x) => {
                tempState = {
                    ...tempState,
                    ...x
                }
            })
            return {
                ...tempState
            };
        default:
            return state;
    }
};

export default siteReducer;
