import React, { useEffect, useRef } from 'react';
import "./ParticleBackground.css";

const ParticleBackground = ({interval}) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 360;
        canvas.height = 360;

        let particlesArray = [];

        const numberOfParticles = 1;

        let isInterval = true;

        if (interval < 2) {
           isInterval = false;
        }

        const maxLifeSpan = 4000; // Maximale Lebensdauer in Millisekunden

        // Partikel-Objekt-Konstruktor
        class Particle {
            constructor() {
                this.x = canvas.width / 2;
                this.y = canvas.height / 2;
                this.size = Math.random() * 5 + 2;
                this.speedX = Math.random() * ((interval / 5000) + 0.4) - (((interval / 5000) + 0.4) / 2);
                this.speedY = Math.random() * ((interval / 5000) + 0.4) - (((interval / 5000) + 0.4) / 2);
                this.createdAt = Date.now();
            }
            update() {
                this.x += this.speedX;
                this.y += this.speedY;
                if (this.size > 0.2) this.size -= 0.1;
            }
            draw() {
                ctx.fillStyle = 'rgba(255,255,255,0.8)';
                ctx.beginPath();
                ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
                ctx.fill();
            }
            isExpired() {
                // Überprüft, ob das Partikel länger als die maximale Lebensdauer gelebt hat
                return (Date.now() - this.createdAt) > maxLifeSpan;
            }
        }

        // Partikel initialisieren
        function init() {
            if (isInterval) {
                setInterval(()=>{
                    for (let i = 0; i < numberOfParticles; i++) {
                        particlesArray.push(new Particle());
                    }
                }, (10000 / interval))
            }
        }

        // Partikel animieren
        function animate() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            particlesArray.forEach((particle, index) => {
                particle.update();
                particle.draw();
                // Prüfen, ob das Partikel abgelaufen ist und es gegebenenfalls entfernen
                if (particle.isExpired()) {
                    particlesArray.splice(index, 1);
                }
            });

            if (particlesArray.length < numberOfParticles) {
                particlesArray.push(new Particle()); // Neue Partikel hinzufügen, um das Array aufzufüllen
            }

            requestAnimationFrame(animate);
        }

        init();
        animate();

        return () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear the canvas on unmount
        }
    }, []);

    return (
        <canvas ref={canvasRef} id={"ParticleBackground"} />
    );
}

export default ParticleBackground;
