import React, {useEffect, useState} from 'react';
import "./Header.css";

import {Link} from "react-router-dom";
import LanguageSwitcher from "./languageSwitcher/LanguageSwitcher";
import BurgerMenu from "./burgermenu/BurgerMenu";

function Header() {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        setInterval(() => {
            setScreenWidth(window.innerWidth);
        }, 100)
    }, []);

    return (
        <div id="HeaderNav">
            <div className={"HeaderNavGroup HeaderNavGroupLeft"}>
                <div className={"HeaderNavLogo"}>
                    <Link to={"/"}>
                        <img src="https://assets.uxnetwork.eu/logos/white.png" alt="logo" className='uxnetworkLogo'/>
                        <h1>UX-NETWORK</h1>
                    </Link>
                </div>
                {
                    screenWidth > 600 &&
                <LanguageSwitcher/>
                }
                <div className={"HeaderNavSlice"}>

                </div>
                <ul className="nav">
                    {
                        screenWidth > 860 &&
                        <li className="nav-item">
                            <Link className="nav-link active" to={"/"}>Home</Link>
                        </li>
                    }
                    <li className="nav-item">
                        <Link className="nav-link" to={"/for-creators"}><img
                            src="https://assets.uxnetwork.eu/logos/white.png" alt="logo" className='miniLogo'/>Creators</Link>
                    </li>
                    <li className="nav-item">

                        <Link className="nav-link" to={"/for-companies"}><img
                            src="https://assets.uxnetwork.eu/logos/white.png" alt="logo" className='miniLogo'/>Companies</Link>
                    </li>
                </ul>
            </div>
            <div className={"HeaderNavGroup HeaderNavGroupRight"}>
                <BurgerMenu />
            </div>
        </div>
    );
}

export default Header;