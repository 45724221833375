import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {verifyUser} from '../../../api'; // Importiere die API-Funktion

const Logout = () => {
    const dispatch = useDispatch();

    const handleVerify = async () => {
        try {
            const response = await verifyUser();
            dispatch({
                type: 'ADD_ALERT', payload: {
                    message: response.message,
                    type: "success"
                }
            });
            window.location = "/";
        } catch (error) {
            dispatch({
                type: 'ADD_ALERT', payload: {
                    message: error.message,
                    type: "success"
                }
            });
            console.error('Verify Fehler:', error);
        }
    };


    useEffect(() => {
        handleVerify();
    }, [dispatch]);

    return (
        <div>
            <h2></h2>
        </div>
    );
};

export default Logout;
