import React, { useState } from 'react';
import './Dashboard.css';
import MainContent from "./maincontent/MainContent";

function Dashboard() {
    const [activeTab, setActiveTab] = useState('home');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }

    return (
        <div id="Dashboard">
            <MainContent activeTab={activeTab} />
        </div>
    );
}

export default Dashboard;
