import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "./CountUpAnimation.css";
import ParticleBackground from "./particleBackground/ParticleBackground";

const CountUpAnimation = ({ streamerInfo }) => {
    const { t } = useTranslation();
    const [counters, setCounters] = useState({
        follower: 0,
        viewer: 0,
        live: 0,
        streamer: 0
    });

    useEffect(() => {
        const duration = 1000;
        const frameRate = 1000 / 60;
        const totalFrames = Math.round(duration / frameRate);
        let frame = 0;
        const timer = setInterval(() => {
            frame++;
            if (frame <= totalFrames) {
                const progress = frame / totalFrames;
                setCounters({
                    follower: Math.round(streamerInfo.follower * progress),
                    viewer: Math.round(streamerInfo.viewer * progress),
                    live: Math.round(streamerInfo.live * progress),
                    streamer: Math.round(streamerInfo.streamer * progress)
                });
            } else {
                clearInterval(timer);
            }
        }, frameRate);

        return () => clearInterval(timer);
    }, [streamerInfo]);

    return (
        <div id="CountUpAnimation">
            <div><ParticleBackground interval={streamerInfo.follower} /><h2>{counters.follower}</h2><h3>{t('countUp_follower')}</h3></div>
            <div><ParticleBackground interval={streamerInfo.viewer * 20} /><h2>{counters.viewer}</h2><h3>{t('countUp_viewer')}</h3></div>
            <div><ParticleBackground interval={(streamerInfo.live / streamerInfo.streamer) * 100} /><h2>{counters.live}</h2><h3>{t('countUp_live')}</h3></div>
            <div><ParticleBackground interval={streamerInfo.streamer * 100} /><h2>{counters.streamer}</h2><h3>{t('countUp_streamer')}</h3></div>
        </div>
    );
};

export default CountUpAnimation;
