import {generateUUID} from "../utils/generateUUID";

const initialAlertState = [];

const alertReducer = (state = initialAlertState, action) => {
    switch (action.type) {
        case 'ADD_ALERT':
            return [
                ...state,
                {
                    id: generateUUID(), // Generiere eine eindeutige ID für jeden Alert
                    message: action.payload.message,
                    type: action.payload.type,
                    delay: action.payload.delay || 3000 // Standardverzögerung, falls nicht spezifiziert
                }
            ];
        case 'REMOVE_ALERT':
            return state.filter(alert => alert.id !== action.payload.id);
        default:
            return state;
    }
};

export default alertReducer
