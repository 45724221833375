import React, {useEffect, useState} from 'react';
import "./Register.css";
import {useDispatch} from "react-redux";
import {registerUser} from "../../../api";

function Register() {
    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const dispatch = useDispatch();

    const handleRegister = async () => {
        try {
            const response = await registerUser({username, password, email});
            dispatch({
                type: 'ADD_ALERT', payload: {
                    message: response.message,
                    type: "success"
                }
            });
        } catch (error) {
            dispatch({
                type: 'ADD_ALERT', payload: {
                    message: error.response.data.message,
                    type: "error"
                }
            });
            console.error('Login Fehler:', error);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("authToken")) {
            window.location = "/"
        }
    }, []);
    return (
        <>
            {1 === 1 && <div className={"loginCenter"}>
                    <h2>Register</h2>
                    <div>
                        <p className={"smallText"}>Username:</p>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Max Mustermann"
                            name="username"
                        />
                    </div>
                    <div>
                        <p className={"smallText"}>E-Mail:</p>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="service@uxnetwork.eu"
                            name="E-Mail"
                        />
                    </div>
                    <div>
                        <p className={"smallText"}>Passwort:</p>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="IAmASecurePassword123!"
                            name="Password"
                        />
                    </div>

                    <button onClick={handleRegister}>Register</button>
                </div>
                || <div className={"loginCenter"}>
                    <h4>
                        Danke für dein verbundenes Interesse!
                    </h4>

                    <p>
                        Ab dem Offiziellen Release kannst du dich hier registrieren!
                    </p>
                </div>}
        </>

    );
}

export default Register;