import axios from 'axios';

const BASE_URL = 'https://api.uxnetwork.eu';

// Funktion zum Registrieren eines neuen Benutzers
export async function registerUser(userData) {
    try {
        const response = await axios.post(`${BASE_URL}/user/register`,
            userData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return response.data;
    } catch (error) {
        console.error('Fehler bei der Registrierung:', error);
        throw error;
    }
}

// Funktion zum Einloggen eines Benutzers
export async function loginUser(credentials) {
    try {
        const response = await axios.post(`${BASE_URL}/user/login`, credentials, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        });

        localStorage.setItem("authToken", response.data.token)

        return response.data;
    } catch (error) {
        console.error('Fehler beim Einloggen:', error);
        throw error;
    }
}

// Funktion zum Abmelden eines Benutzers
export async function logoutUser() {
    try {
        const response = await axios.post(`${BASE_URL}/user/logout`, {
            "authToken": localStorage.getItem("authToken")
        }, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Fehler bei der Abmeldung:', error);
        throw error;
    }
}

export async function verifyUser() {
    try {
        const response = await axios.post(`${BASE_URL}/user/verify`, {
            "verifyToken": window.location.href.split('?token=')[1]
        }, {
            withCredentials: true
        });

        console.log(response)

        return response.data;
    } catch (error) {
        console.error('Fehler beim Überprüfen des Auth-Status:', error);
        throw error;
    }
}

// Funktion zum Überprüfen des Anmeldestatus
export async function checkAuthStatus() {
    try {
        const response = await axios.post(`${BASE_URL}/user/check`, {
            "authToken": localStorage.getItem("authToken")
        }, {
            withCredentials: true
        });

        return response.data;
    } catch (error) {
        localStorage.removeItem("authToken")
        window.location = "/";
        console.error('Fehler beim Überprüfen des Auth-Status:', error);
        throw error;
    }
}

// Funktion zum Aktualisieren des Benutzerprofils
export async function updateUserProfile(userData) {
    console.log(userData)
    try {
        const response = await axios.put(`${BASE_URL}/user/update`, {
            "authToken": localStorage.getItem("authToken")
        }, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Fehler beim Aktualisieren des Benutzerprofils:', error);
        throw error;
    }
}

export async function getStreamerInfo() {
    try {
        const response = await axios.get(`${BASE_URL}/user/streamer`, {},);

        let kickStreamerInfo = {
            follower: 0,
            viewer: 0,
            live: 0,
            streamer: 0
        }

        await Promise.all(response.data.payload.kickStreamer.map(async (x) => {
            try {
                const tempStreamer = await axios.get(`https://kick.com/api/v1/channels/${x}`);

                kickStreamerInfo = {
                    follower: kickStreamerInfo.follower + tempStreamer.data.followersCount,
                    viewer: kickStreamerInfo.viewer + (tempStreamer.data.livestream && tempStreamer.data.livestream.viewer_count),
                    live: kickStreamerInfo.live + (tempStreamer.data.livestream && 1),
                    streamer: kickStreamerInfo.streamer + 1
                };


            } catch (error) {
                console.error('KICK.COM API FEHLER', error);
                throw error;
            }
        }));

        let twitchStreamerInfo = {
            follower: 0,
            viewer: 0,
            live: 0,
            streamer: 0
        }

        return {
            kickStreamerInfo,
            twitchStreamerInfo,
            kickStreamer: response.data.payload.kickStreamer,
            twitchStreamer: response.data.payload.twitchStreamer
        };

    } catch (error) {
        console.error('UXNETWORK API FEHLER', error);
        throw error;
    }
}