import React from 'react';
import { useTranslation } from 'react-i18next';
import "./LanguageSwitcher.css";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <div id={"HeaderLanguageSwitcher"}>
            <button onClick={() => changeLanguage('en')}>
                EN
            </button>
            <div className={"divider"}>-</div>
            <button onClick={() => changeLanguage('de')}>
                DE
            </button>
        </div>
    );
};

export default LanguageSwitcher;
