import React from 'react';
import { useTranslation } from 'react-i18next';
import "./For-creators.css";

function Forcreators() {
    const { t } = useTranslation();

    return (
        <div className="container">
            <header className="custom-header text-center">
                <h1>{t('creators_header')}</h1>
            </header>
            <main>
                <section className="custom-section d-flex justify-content-around">
                    <div className="info-box">
                        <i className="bi bi-briefcase-fill icon"></i>
                        <h2>{t('expand_your_reach')}</h2>
                        <p>{t('join_ux_network')}</p>
                    </div>
                    <div className="info-box">
                        <i className="bi bi-bar-chart-line-fill icon"></i>
                        <h2>{t('expand_your_reach')}</h2>
                        <ol className="custom-list">
                            <li>{t('creators_process1')}</li>
                            <li>{t('creators_process2')}</li>
                            <li>{t('creators_process3')}</li>
                        </ol>
                    </div>
                    <div className="info-box">
                        <i className="bi bi-stars icon"></i>
                        <h2>{t('benefits')}</h2>
                        <ul className="custom-list">
                            <li>{t('creators_benefit1')}</li>
                            <li>{t('creators_benefit2')}</li>
                            <li>{t('creators_benefit3')}</li>
                        </ul>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Forcreators;
