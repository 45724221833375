import React, {useEffect, useState} from 'react';
import './Sidebar.css';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

function Sidebar() {

    const user = useSelector(state => state.user);

    const site = useSelector(state => state.site);

    const [staticDashboardPages, setStaticDashboardPages] = useState([])

    useEffect(() => {
        setStaticDashboardPages([{
            "name": "Dashboard",
            "link": "/dashboard",
            "iconType": "bi-house-door"
        }])
    }, []);

    const [isSidebarGrown, setIsSidebarGrown] = useState(false);

    useEffect(() => {
        setIsSidebarGrown(site.isSidebarGrown)
    }, [site])

    const handleSidebarToggle = () => {
        setIsSidebarGrown((prevVisibility) => !prevVisibility);
    };

    return (<>
            {
                isSidebarGrown && <div id={"SidebarBackground"} onClick={handleSidebarToggle}></div>
            }
            <div
                className={`flex-column flex-shrink-0 p-3 text-white ${isSidebarGrown ? 'active' : ''}`}
                id="Sidebar">
                <Link to="/"
                      className="d-flex align-items-center mb-md-0 me-md-auto text-white text-decoration-none">
                    <img src="https://assets.uxnetwork.eu/logos/white.png" alt="logo"
                         className={"logo"}/>
                    <span className="fs-4">UX-Network</span>
                </Link>
                <hr/>
                <ul className="nav nav-pills flex-column mb-auto">
                    {
                        user && <>
                            {
                                staticDashboardPages.map((staticPage) => <li className="nav-item">
                                    <Link to={staticPage.link} className="nav-link" aria-current="page">
                                        <i className={`bi ${staticPage.iconType}`}></i>
                                        <p>{staticPage.name}</p>
                                    </Link>
                                </li>)
                            }
                            {
                                user.companies.length !== 0 && <>
                                    <hr/>
                                    {
                                        isSidebarGrown && <h2>Companies</h2>
                                    }
                                    {
                                        user.companies.map((company) => <li className="nav-item">
                                            <Link className="nav-link"
                                                  aria-current="page"
                                                  to={`/dashboard/company?id=${company.company_id}`}>
                                                <img src={company.logo_url || company.website_url + "/logo192.png"}
                                                     alt=""/>
                                                <p>{company.name}</p>
                                            </Link>
                                        </li>)
                                    }
                                </>
                            }
                            {
                                user.projects.length !== 0 && <>
                                    <hr/>
                                    {
                                        isSidebarGrown && <h2>Projects</h2>
                                    }
                                    {
                                        user.projects.map((project) => <li className="nav-item">
                                            <Link className="nav-link"
                                                  aria-current="page"
                                                  to={`/dashboard/project?id=${project.project_id}`}>
                                                <img src={project.logo_url || project.website_url + "/logo192.png"}
                                                     alt=""/>
                                                <p>{project.name}</p>
                                            </Link>
                                        </li>)
                                    }
                                </>
                            }
                        </> || <>
                            {isSidebarGrown && <>
                                PLEASE LOGIN BELOW
                            </>}
                        </>
                    }

                </ul>
                <hr/>
                <div className="dropup">
                    <div className={`sidebarHandle ${isSidebarGrown ? 'active' : ''}`}>
                        <button onClick={handleSidebarToggle}>
                            <i
                                className={`bi ${isSidebarGrown ? 'bi-arrow-bar-left' : 'bi-arrow-bar-right'}`}
                            ></i>
                        </button>
                    </div>
                    {
                        user && <>
                            <Link to="/dashboard"
                                  className={`d-flex align-items-center text-white text-decoration-none ${isSidebarGrown ? 'dropdown-toggle' : ''}`}
                                  id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                <div>
                                    <img src={user.profile_picture_url || "xxx"} alt="" width="32" height="32"
                                         className="rounded-circle me-2"/>
                                    <span><strong>{user.username || "xxx"}</strong></span>
                                </div>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-dark text-small shadow dropdownUser"
                                aria-labelledby="dropdownUser">
                                <li><Link className="dropdown-item dropdown-logout" to="/logout"><i
                                    className="bi bi-box-arrow-right"></i>Logout</Link>
                                </li>
                            </ul>
                        </> || <>
                            <Link to="/login"
                                  className={`d-flex align-items-center text-white text-decoration-none ${isSidebarGrown ? 'dropdown-toggle' : ''}`}
                                  id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                <div>
                                    <img
                                        src={"https://cloud.uxnetwork.eu/index.php/s/HjrtQwz7PNFdc3i/download/profileImage.png"}
                                        alt="" width="32" height="32"
                                        className="rounded-circle me-2"/>
                                    <span><strong>NEW HERE?</strong></span>
                                </div>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-dark text-small shadow dropdownUser"
                                aria-labelledby="dropdownUser">
                                <li><Link className="dropdown-item dropdown-logout" to="/login"><i
                                    className="bi bi-box-arrow-in-left" onClick={handleSidebarToggle}></i>Login</Link>
                                </li>
                                <li><Link className="dropdown-item dropdown-logout" to="/register"><i
                                    className="bi bi-r-circle" onClick={handleSidebarToggle}></i>Register</Link>
                                </li>
                            </ul>
                        </>
                    }

                </div>
            </div>
        </>
    )

}

export default Sidebar;
