import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "./StreamerInfo.css";
import { getStreamerInfo } from "../../../../api";
import CountUpAnimation from "./countUpAnimation/CountUpAnimation";

function StreamerInfo() {
    const { t } = useTranslation();
    const [kickStreamerInfo, setKickStreamerInfo] = useState(null);

    const [kickStreamer, setKickStreamer] = useState([])

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoaded) {
            getStreamerInfo().then(data => {
                setKickStreamerInfo(data.kickStreamerInfo);
                setKickStreamer(data.kickStreamer);
                setIsLoaded(true);  // Setze den Zustand auf true, nachdem die Daten geladen wurden
            }).catch(error => {
                console.error('Fehler beim Laden der Streamer-Informationen:', error);
            });
        }
    }, [isLoaded]);

    return (
        <div id="StreamerInfo">
            <h1>{t('streamerInfo_title')}</h1>
            {kickStreamerInfo ? (
                <CountUpAnimation streamerInfo={kickStreamerInfo} />
            ) : (
                <div className="spinner-grow" role="status">
                    <span className="visually-hidden"></span>
                </div>
            )}
            {window.innerWidth > 860 && <div className={"embeds"}>
                <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {
                            kickStreamer.map((x, index) => (
                                <div key={index} className={`carousel-item ${index < 1 && "active"}`}>
                                    <iframe
                                        src={'https://player.kick.com/' + x + '?autoplay=true&muted=true'}
                                        height="100%"
                                        width="100%"
                                        frameBorder="0"
                                        scrolling="no"
                                        allowFullScreen={true}
                                        className="d-block w-100">
                                    </iframe>
                                </div>
                            ))
                        }
                    </div>
                    <button className="carousel-control-prev" type="button"
                            data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button"
                            data-bs-target="#carouselExampleFade" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>}
        </div>
    );
}

export default StreamerInfo;
