

function MainContent({ activeTab }) {
    const renderContent = () => {
        switch (activeTab) {
            default:
                return <h1>Willkommen im Dashboard</h1>;
        }
    };

    return (
        <div className="dashboard-main">
            {renderContent()}
        </div>
    );
}

export default MainContent;
