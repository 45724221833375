import "./Footer.css";

function Footer() {
    return (
        <div id="Footer">
            <div className="FooterContent">
                <div className={"FooterContentTitle"}>
                    <div>
                        <img src="https://assets.uxnetwork.eu/logos/white.png" alt="logo" className='uxnetworkLogo'/>
                        <h1>
                            UX-NETWORK
                        </h1>
                    </div>
                    <p>
                        The new and innovative Network System for Creators and Companies to connect.
                    </p>
                </div>
                <div>

                </div>
            </div>
            <div className={"FooterCopyright"}>
                Copyright © {new Date().getFullYear()} UX - All rights reserved
            </div>
        </div>
    );
}

export default Footer;